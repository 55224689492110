import { IUpdateMessageElement } from '@kontent-ai/smart-link';
import {
  ICancellablePromise,
  makeCancellablePromise,
  notNullNorUndefined,
  swallowCancelledPromiseError,
} from '@kontent-ai/utils';
import Immutable from 'immutable';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch } from '../../../_shared/hooks/useDispatch.ts';
import { useSelector } from '../../../_shared/hooks/useSelector.ts';
import { isEditableElement } from '../../contentInventory/content/models/contentTypeElements/compiledTypeElementTypeGuards.ts';
import { ElementsChangeObserver } from '../../itemEditor/features/ContentItemEditing/components/elements/customElement/ElementsChangeObserver.tsx';
import { getEditedContentItemType } from '../../itemEditor/selectors/getEditedContentItemType.ts';
import { getElementById } from '../../itemEditor/stores/utils/contentItemElementsUtils.ts';
import { getElementDataForPreview } from '../actions/thunkWebSpotlightActions.ts';

type LivePreviewElementUpdaterProps = {
  readonly onUpdate: (elements: IUpdateMessageElement[]) => void;
};

const emptySet: Immutable.Set<Uuid> = Immutable.Set();

export const LivePreviewElementUpdater: React.FC<LivePreviewElementUpdaterProps> = ({
  onUpdate,
}) => {
  const onElementsChangePromise = useRef<ICancellablePromise | null>(null);
  const typeElements = useSelector((s) => getEditedContentItemType(s)?.contentElements);
  const editedElements = useSelector((state) => state.contentApp.editedContentItemVariantElements);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => onElementsChangePromise.current?.cancel();
  }, []);

  const onElementsChanged = useCallback(
    (elementCodenames: Immutable.Set<string>) => {
      const prepareElementDataForPreview = async () => {
        return (
          await Promise.all(
            elementCodenames.toArray().map(async (elementCodename) => {
              const typeElement = typeElements?.find((el) => el.codename === elementCodename);
              if (!isEditableElement(typeElement)) {
                return null;
              }

              const element = getElementById(typeElement.elementId, editedElements);
              if (!element) {
                return null;
              }

              const data = await dispatch(getElementDataForPreview(element, typeElement));
              if (!data) {
                return null;
              }

              return {
                ...data,
                element: {
                  id: typeElement.elementId,
                  codename: elementCodename,
                },
              };
            }),
          )
        ).filter(notNullNorUndefined);
      };

      onElementsChangePromise.current = makeCancellablePromise(prepareElementDataForPreview)
        .then(onUpdate)
        .catch(swallowCancelledPromiseError);
    },
    [editedElements, onUpdate, typeElements],
  );

  const debouncedOnElementsChanged = useDebouncedCallback(onElementsChanged, 150);

  if (!typeElements) {
    return null;
  }

  return (
    <ElementsChangeObserver
      elements={editedElements}
      observeAllElements
      observedElementIds={emptySet}
      onElementsChanged={debouncedOnElementsChanged}
      typeElements={typeElements}
    />
  );
};

LivePreviewElementUpdater.displayName = 'LivePreviewElementUpdater';
